/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, {
  ChangeEvent, useEffect, useRef, useState,
} from 'react';
import { getChainConfig } from '@utils';
import { ChainConfig } from '@types';
import {
  DropdownSelect, Loader, toastClose, toastInfo,
} from '@components';
import { useEthPrice } from '@hooks';
import { useWeb3React } from '@web3-react/core';
// import { profileSelectors } from '@store/profile/selectors';
// import { useSelector } from 'react-redux';
import BigNumber from 'bignumber.js';
import Web3 from 'web3';
import { arbitrumChain, linea } from '@constants';
import styles from './styles.module.scss';
import { SelectMob } from './SelectMob';
import {
  useBalance,
  useGetRefuelInfo, useMaxRefuelFee, useRefuel, useRefuelSettings,
} from './hoohs';

const chainConfig = getChainConfig();

const Bridge = () => {
  const { chainId } = useWeb3React();
  const [chainFrom, setChainFrom] = useState<ChainConfig|undefined>();
  const [chainTo, setChainTo] = useState<ChainConfig>();
  const [input, setInput] = useState('');
  const [amountInUsd, setAmountInUsd] = useState('');
  const { isRefueling, refuel } = useRefuel();
  const { ethPrice } = useEthPrice();
  const { maxRefuelAmount, minRefuelAmount } = useRefuelSettings(chainFrom?.id);
  const { maxRefuelFee, isFetching: isFetchingMaxRefuelFee } = useMaxRefuelFee({
    amount: input,
    chainFromId: chainFrom?.id,
    chainToId: chainTo?.id,
  });
  const balance = useBalance(chainFrom?.id);

  const inputRef = useRef<HTMLInputElement>(null);
  const measureRef = useRef<HTMLSpanElement>(null);
  const [suffixPosition, setSuffixPosition] = useState(0);
  const [suffixWidth, setSuffixWidth] = useState(0);
  const suffixRef = useRef<HTMLSpanElement>(null);
  const { total, refuelCost } = useGetRefuelInfo({
    amount: input,
    chainFromId: chainFrom?.id,
    chainToId: chainTo?.id,
  });
  useEffect(() => {
    if (measureRef.current && inputRef.current && suffixRef.current) {
      const textWidth = measureRef.current.offsetWidth;
      const inputWidth = inputRef.current.offsetWidth;
      const suffixNewWidth = suffixRef.current.offsetWidth;
      setSuffixWidth(suffixNewWidth);

      const maxPosition = inputWidth - suffixWidth - 8;
      setSuffixPosition(Math.min(textWidth, maxPosition));
    }
  }, [input, amountInUsd, suffixWidth]);

  const onChangeInputHandler = ({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
    let formattedValue = value.replace(/[^\d,.]/g, '');
    formattedValue = formattedValue.replace(/,/g, '.');

    // @ts-ignore: worked well so far
    if (formattedValue?.match(/\./g)?.length > 1) {
      formattedValue = formattedValue.slice(0, Math.max(0, formattedValue.lastIndexOf('.')));
    }

    if (formattedValue.split('.').length && formattedValue.split('.')?.[1]?.length > 18) {
      return;
    }

    setInput(formattedValue);
    const ethValue = +formattedValue / +ethPrice;
    setAmountInUsd(ethValue.toString());
  };

  const setChainFromHandler = (id: number | string) => {
    const chain = chainConfig.find((item) => item.id === id);

    if (!chain) {
      return;
    }

    setChainFrom(chain);

    if (chainTo?.id === id) {
      setChainTo(undefined);
    }
  };

  const setChainToHandler = (id: number | string) => {
    const chain = chainConfig.find((item) => item.id === id);

    if (!chain) {
      return;
    }

    setChainTo(chain);
  };

  const onClickSwitchChainsHandler = () => {
    const from = chainFrom;
    setChainFrom(chainTo);
    setChainTo(from);
  };

  const onClickMaxHandler = () => {
    if (!maxRefuelAmount || !ethPrice || isFetchingMaxRefuelFee) {
      return;
    }

    if (!BigNumber(maxRefuelFee).isNaN() && !BigNumber(balance).isNaN()) {
      const availableAmount = BigNumber(balance).minus(maxRefuelFee);
      if (availableAmount.isLessThan(maxRefuelAmount)) {
        if (availableAmount.isLessThan(0)) {
          setInput('0');
          setAmountInUsd('0');
          return;
        }
        const amountBn = BigNumber(Web3.utils.fromWei(availableAmount.toString()))
          .multipliedBy(0.9);
        setAmountInUsd(amountBn.dividedBy(+ethPrice).toFixed(2));
        setInput(amountBn.toFixed(5));

        return;
      }
    }

    const amountBn = BigNumber(Web3.utils.fromWei(maxRefuelAmount.toString()))
      .multipliedBy(0.9);
    setAmountInUsd(amountBn.dividedBy(ethPrice).toFixed(2));
    setInput(amountBn.toFixed(5));
  };

  const onClickMinHandler = () => {
    if (!minRefuelAmount || !ethPrice) {
      return;
    }
    setInput((+Web3.utils.fromWei(minRefuelAmount)).toFixed(5));
    setAmountInUsd((+Web3.utils.fromWei(minRefuelAmount).toString() / +ethPrice).toFixed(2));
  };
  const onClickRefuelHandler = () => {
    if (isRefueling) {
      return;
    }
    if (!chainFrom?.id) {
      toastClose();
      toastInfo('Select current network');

      return;
    }

    if (!chainTo?.id) {
      toastClose();
      toastInfo('Select destination network');

      return;
    }

    if (!input || +input <= 0) {
      toastClose();
      toastInfo('Enter amount tokens to refuel');

      return;
    }

    if (chainFrom?.id === chainTo?.id) {
      toastClose();
      toastInfo('Current chain and destination chain should be different');

      return;
    }

    if (chainFrom?.id !== chainId) {
      toastClose();
      toastInfo(`Please switch to the selected ${chainFrom?.title}`);

      return;
    }

    if (input && +input < +Web3.utils.fromWei(minRefuelAmount).toString()) {
      toastClose();
      toastInfo(`Min refuel amount is ${Web3.utils.fromWei(minRefuelAmount).toString()} ETH`);

      return;
    }

    if (!+balance) {
      toastClose();
      toastInfo('Insufficient balance.');

      return;
    }

    const availableAmount = BigNumber(balance).minus(maxRefuelFee);

    if (availableAmount.isLessThan(Web3.utils.toWei(input))) {
      toastClose();
      toastInfo('Insufficient balance.');

      return;
    }

    if (BigNumber(Web3.utils.toWei(input)).isGreaterThan(maxRefuelAmount)) {
      toastClose();
      toastInfo(`Max refuel amount is ${Web3.utils.fromWei(maxRefuelAmount).toString()} ETH`);

      return;
    }

    refuel({
      amount: input,
      chainFromId: chainFrom?.id,
      chainToId: chainTo?.id,
    });
  };

  useEffect(() => {
    if (chainId) {
      const targetChainFromId = chainId || arbitrumChain?.chainId;
      setChainFrom(chainConfig
        .find((chain) => chain.id === targetChainFromId) as ChainConfig);

      if (!chainTo?.id) {
        const targetChainToId = linea?.chainId === chainId
          ? arbitrumChain?.chainId : linea?.chainId;
        setChainTo(chainConfig
          .find((chain) => chain.id === targetChainToId) as ChainConfig);
      }
    }
  }, [chainId]);

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <div className={styles.card}>
          <div className={styles.title}>
            Gas Refuel
            <div className={styles.subtitle}>Native asset bridge</div>
          </div>
          <div className={styles.selects}>
            <div className={styles.selectMob}>
              <SelectMob
                items={chainConfig}
                selectedItem={chainFrom}
                isWithGrid
                setSelectedItem={setChainFromHandler}
                customTitle="Select chain"
                customButton={(
                  <div>
                    <div className={styles.labelGray}>From</div>
                    <div className={styles.selector}>
                      <div className={styles.selectedChain}>
                        {chainFrom?.icon && (
                        <img src={chainFrom.icon} width={24} height={24} alt="" />
                        )}
                        {chainFrom?.title || ''}
                      </div>
                      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M19.9201 8.94995L13.4001 15.47C12.6301 16.24 11.3701 16.24 10.6001 15.47L4.08008 8.94995"
                          stroke="#F8F7F7"
                          strokeWidth="1.5"
                          strokeMiterlimit="10"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>

                    </div>
                  </div>
                )}
                {...{ setChainFromHandler }}
              />
            </div>
            <div className={styles.selectDesk}>
              <DropdownSelect
                items={chainConfig}
                selectedItem={chainFrom}
                isWithGrid
                setSelectedItem={setChainFromHandler}
                customButton={(
                  <div>
                    <div className={styles.labelGray}>From</div>
                    <div className={styles.selector}>
                      <div className={styles.selectedChain}>
                        {chainFrom?.icon && (
                        <img src={chainFrom.icon} width={24} height={24} alt="" />
                        )}
                        {chainFrom?.title || ''}
                      </div>
                      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M19.9201 8.94995L13.4001 15.47C12.6301 16.24 11.3701 16.24 10.6001 15.47L4.08008 8.94995"
                          stroke="#F8F7F7"
                          strokeWidth="1.5"
                          strokeMiterlimit="10"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </div>
                  </div>
                )}
                {...{ setChainFromHandler }}
              />
            </div>
            <div
              style={{ cursor: 'pointer' }}
              className={styles.switchIcon}
              onClick={onClickSwitchChainsHandler}
              onKeyPress={() => null}
            >
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M20.5 14.99L15.49 20.01"
                    stroke="#F8F7F7"
                    strokeWidth="1.5"
                    strokeMiterlimit="10"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M3.5 14.99H20.5"
                    stroke="#F8F7F7"
                    strokeWidth="1.5"
                    strokeMiterlimit="10"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M3.5 9.00999L8.51 3.98999"
                    stroke="#F8F7F7"
                    strokeWidth="1.5"
                    strokeMiterlimit="10"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M20.5 9.01001H3.5"
                    stroke="#F8F7F7"
                    strokeWidth="1.5"
                    strokeMiterlimit="10"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </svg>

            </div>
            <div className={styles.selectMob}>
              <SelectMob
                items={chainConfig}
                selectedItem={chainTo}
                isWithGrid
                setSelectedItem={setChainToHandler}
                customTitle="Select chain"
                customButton={(
                  <div>
                    <div className={styles.labelGray}>To</div>
                    <div className={styles.selector}>
                      <div className={styles.selectedChain}>
                        {chainTo?.icon && (
                        <img src={chainTo.icon} width={24} height={24} alt="" />
                        )}
                        {chainTo?.title || ''}
                      </div>
                      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M19.9201 8.94995L13.4001 15.47C12.6301 16.24 11.3701 16.24 10.6001 15.47L4.08008 8.94995"
                          stroke="#F8F7F7"
                          strokeWidth="1.5"
                          strokeMiterlimit="10"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>

                    </div>
                  </div>
                )}
                {...{ setChainFromHandler }}
              />
            </div>

            <div className={styles.selectDesk}>
              <DropdownSelect
                items={chainConfig} // .filter(chain => chain.id !== chainFrom?.id)}
                selectedItem={chainTo}
                isWithGrid
                customTitle="Select chain"
                setSelectedItem={setChainToHandler}
                customButton={(
                  <div>
                    <div className={styles.labelGray}>To</div>
                    <div className={styles.selector}>
                      <div className={styles.selectedChain}>
                        {chainTo?.icon && (
                        <img src={chainTo.icon} width={24} height={24} alt="" />
                        )}
                        {chainTo?.title || ''}
                      </div>
                      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M19.9201 8.94995L13.4001 15.47C12.6301 16.24 11.3701 16.24 10.6001 15.47L4.08008 8.94995"
                          stroke="#F8F7F7"
                          strokeWidth="1.5"
                          strokeMiterlimit="10"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </div>
                  </div>
                )}
                {...{ setChainToHandler }}
              />
            </div>
          </div>
        </div>
        <div className={styles.card}>
          <div className={styles.title}>
            Send ETH
          </div>

          <div className={styles.form}>
            <div className={styles.label}>
              Enter amount:
            </div>
            <div className={styles.inputs}>
              <div
                onKeyPress={() => null}
                className={styles.controlBtn}
                onClick={onClickMinHandler}
              >
                MIN
              </div>
              {/* <div className={styles.inputContainer}> */}
              <div className={styles.inputWrapper}>
                {/* <div className={styles.inputValueWrapper}> */}
                <span ref={measureRef} className={styles.measureText}>
                  {input}
                </span>
                <input
                  className={styles.mainInput}
                  type="text"
                  value={input}
                  onChange={onChangeInputHandler}
                  placeholder=""
                  ref={inputRef}
                  style={{ paddingRight: `${suffixWidth + 8}px` }}
                />
                <span ref={suffixRef} className={styles.suffix} style={{ left: `${suffixPosition}px` }}>
                  {input && +input > 0 ? `(${+amountInUsd > 0 ? (+amountInUsd).toFixed(2) : '0'}USD)` : ''}
                </span>
                {/* </div> */}
                {/* </div> */}
                <span className={styles.fixedText}>ETH</span>
              </div>
              <div
                onKeyPress={() => null}
                className={styles.controlBtn}
                onClick={onClickMaxHandler}
              >
                {isFetchingMaxRefuelFee ? <Loader color="white" />
                  : 'MAX'}
              </div>
            </div>

            <div className={styles.info}>
              <div className={styles.infoTitle}>Summary:</div>
              <div className={styles.infoRows}>
                <div className={styles.infoRow}>
                  <div>
                    Estimated Transfer Time:
                  </div>
                  <div className={styles.infoValue}>
                    {chainTo?.id && chainFrom?.id ? '3 min' : '-'}
                  </div>
                </div>
                <div className={styles.infoRow}>
                  <div>
                    Bridge Fee:
                  </div>
                  <div className={styles.infoValue}>
                    {refuelCost ? (
                      <>
                        {(+refuelCost / +ethPrice).toFixed(2)}

                        USD
                        <div className={styles.infoAdditions}>
                          {(+refuelCost).toFixed(5)}
                        </div>
                      </>
                    ) : '-'}
                  </div>
                </div>
                <div className={styles.infoRow}>
                  <div>
                    Total
                  </div>
                  <div className={styles.infoValue}>
                    {total ? (
                      <>
                        {(+total / +ethPrice).toFixed(2)}
                        USD
                        <div className={styles.infoAdditions}>
                          {(+total).toFixed(5)}
                        </div>
                      </>
                    ) : '-'}
                  </div>
                </div>
              </div>
            </div>
            <div
              className={`${styles.btn} ${isRefueling ? styles.btnLoad : ''}`}
              onClick={onClickRefuelHandler}
              onKeyPress={() => null}
            >
              {isRefueling ? (
                <Loader
                  color="white"
                />
              ) : (
                <>
                  Transfer Liquidity
                  <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M14.9297 5.92969L20.9997 11.9997L14.9297 18.0697" stroke="#E9BB58" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M4 12H20.83" stroke="#E9BB58" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                  </svg>
                </>
              )}

            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export { Bridge };
